#file-uploader-wrapper {
  max-width: 666px;
  text-align: center;
  position: relative;

  #file-uploader-label {
    padding: var(--space-xl);
    display: flex;
    align-items: center;
    justify-content: left;
    border: var(--border-size-s) dashed var(--theme-primary);
    border-radius: var(--border-radius-l);
    background-color: RGBA(var(--theme-rgb-primary), var(--subdued-5-opacity));
    color: var(--font-color-heading);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-m);

    &.drag-active {
      background-color: var(--color-white);
    }

    .narmi-icon-upload {
      padding-right: var(--space-l);
      font-size: var(--font-size-l);
    }

    .upload-button {
      cursor: pointer;
      border: none;
      background-color: transparent;
      padding: 0;
      font-weight: var(--font-weight-normal);

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  #uploaded-file-box {
    padding: var(--space-l) var(--space-xs) var(--space-l) var(--space-l);
    display: flex;
    align-items: center;
    justify-content: left;
    border: var(--border-size-s) solid var(--color-lightGrey);
    border-radius: var(--border-radius-m);
    background-color: var(--color-white);

    &.drag-active {
      background-color: RGBA(var(--theme-rgb-primary), var(--subdued-5-opacity));
    }

    .status-icon-circle-container {
      position: relative;
      display: flex;
      height: 24px;
      width: 24px;
      flex-shrink: 0;

      > .narmi-icon-solid-circle {
        position: absolute;
        color: var(--theme-secondary);
        font-size: 24px;
      }

      > .status-icon-wrapper {
        height: 14px;
        width: 14px;
        margin: auto;

        > .narmi-icon-check {
          position: absolute;
          color: var(--color-white);
          font-size: 14px;
        }
        > .narmi-icon-refresh-cw {
          position: absolute;
          color: var(--color-white);
          font-size: 14px;
          animation: rotation 2s infinite linear;
        }
      }
    }

    .uploaded-file-name {
      text-align: left;
      overflow-wrap: anywhere;
      margin-left: var(--space-s);
      margin-right: var(--space-s);
    }

    .x-button {
      margin-left: auto;
      .narmi-icon-x {
        // overriding the NDS button styling
        font-size: var(--font-size-l);
        color: var(--font-color-primary);
      }
    }
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    inset: 0px;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
