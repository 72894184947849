.agentIQChatBubble {
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  z-index: 9999;
  background-color: var(--theme-primary);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  font-size: 24px;
  border-radius: 50%;
}
