.collapsible-section-card {
  @include minViewport("m") {
    margin-top: var(--space-xl);
  }
  margin-top: 32px;
  padding: var(--space-l);
  border-radius: var(--border-radius-s);
  box-shadow: var(--elevation-low);
  background-color: var(--color-white);

  &.active-step {
    border: var(--border-size-s) solid var(--theme-primary);
  }

  .step-x-of-y-tag {
    font-size: var(--font-size-s);
    color: var(--theme-primary);
    background-color: RGBA(var(--theme-rgb-primary), var(--alpha-5));
    border-radius: 40px;
    padding: var(--space-xxs) var(--space-m);
  }
}
