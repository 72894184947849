.form-header-with-icon {
  // on small and extra small viewports, use the following styles
  margin-bottom: 32px;

  h1 {
    font-size: var(--font-size-heading3);
    line-height: 30px;
  }
  .main-header-container {
    height: 40px;
  }
}

@include minViewport("m") {
  .form-header-with-icon {
    margin-bottom: var(--space-xl);
    h1 {
      font-size: var(--font-size-heading1);
      line-height: 40px;
    }
  }
}

.title-with-chevron {
  position: relative;
  left: -6px;
}

@include minViewport("m") {
  .title-with-chevron {
    left: -30px;
  }
}

.title-with-chevron .narmi-icon-chevron-left {
  font-size: 22px;
  margin-right: var(--space-xs);
}

.subheader-text {
  margin-top: var(--space-xs);
}
