.institution-logo {
  /* hide text */
  text-indent: -100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0px;

  /* size constraints */
  display: inline-block;
  width: 200px;
  height: 50px;

  /* background scaling */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
}
