.password-input {
  position: relative;

  .show-hide-button {
    position: absolute;
    top: 24px;
    right: 16px;
    transform: translateY(-44%);
    // this is to remove the margin of the NDS button which is in-line in order to vertically "center" it
    font-size: 0px;
  }
}
