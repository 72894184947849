.row-item-container {
  padding-bottom: var(--space-m);
  .nds-row {
    padding-top: var(--space-m);
  }
}

.row-item-value {
  text-align: right;
}

.row-item-name {
  font-weight: var(--font-weight-semibold);
}
