@use "sass:map";
@import "../../scss/vars";
@import "../../scss/mixins";

.nds-input-error {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-errorDark);
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0.33em 0 0;

  svg {
    margin: -0.1em 0.4em 0 0.2em;
    height: 1.2em;
    width: 1.2em;
  }
}

@import "./NavBar";
@import "AgentIQBubble";
@import "BackCancelButton/";
@import "ProgressButtons/";
@import "AddPhoneMfaDevice/";
@import "SideBar/";
@import "NotificationContext/";
@import "LoadingEllipses/";
@import "InstitutionLogo/";
@import "InfoSection/";
@import "LoadingContext/";
@import "FileUploader/";
@import "RadioButtonGroups/";
@import "AddressField/";
@import "PasswordTextInput/";
@import "Options/";
@import "FormHeaderWithIcon/";
@import "RowItem/";
@import "Dropdown/";
@import "ProductCard/";
@import "CollapsibleSection/";
@import "AddressAutocompleteInput/";
@import "SearchSelector/";
