.suggestions-container {
  visibility: hidden;
  position: relative;
  z-index: 4;
  border: 1px solid var(--theme-primary);
  width: 100%;
  max-height: 75vh;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 12px 0 10px 0;
  background: var(--color-white);

  &.active {
    visibility: visible;
  }

  .title-row {
    padding: 0 12px 0 12px;
    text-transform: uppercase;
    color: var(--color-mediumGrey);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: default;

    :first-child {
      padding-bottom: var(--space-xs);
    }
  }

  .item-row {
    position: relative;
    padding: 10px 12px 10px 12px;

    &:not(:first-child):not(:last-child) {
      cursor: pointer;
    }
    &:hover:not(:first-child):not(:last-child),
    &.highlighted:not(:first-child):not(:last-child) {
      background: RGBA(var(--theme-rgb-primary), var(--alpha-5));
    }
  }

  .footer-row {
    padding: 10px 12px 0 12px;
  }
}
