.product-card {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  h5 {
    color: var(--color-mediumGrey);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    margin-top: var(--space-l);
    margin-bottom: var(--space-s);
  }

  .selected-accounts {
    margin-top: var(--space-s);
    padding: var(--space-l);
    border-radius: 4px;
    background-color: RGBA(var(--theme-rgb-primary), var(--subdued-5-opacity));
    min-height: 60px;

    .account {
      margin-top: var(--space-xs);

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--theme-primary);

    .narmi-icon-check {
      color: var(--color-white);
    }
  }

  .hidden {
    display: none !important;
  }

  .no-bottom-padding {
    padding-bottom: 0;
  }

  .tag {
    position: absolute;
    top: -30px;
    padding: var(--space-xxs) var(--space-s);
    border-radius: 16px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    line-height: 12.5px;
    font-size: var(--font-size-xxs);
    font-weight: var(--font-weight-semibold);
    background: var(--theme-primary);
    color: white;
  }
}

.product-card-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-l);
  max-width: 766px;

  @media (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }

  ul {
    margin-block-end: 0;

    li:last-of-type {
      p {
        margin-bottom: 0;
      }
    }
  }
}

.product-description {
  color: var(--color-mediumGrey);
  font-size: var(--font-size-s);
  margin-top: 2px;
}

.product-details {
  margin-top: var(--space-l);
}

.product-card-body {
  flex-grow: 1;

  ul {
    padding-left: var(--space-l);
    color: var(--nds-grey);

    li {
      margin-top: var(--space-s);
      margin-bottom: var(--space-s);

      p {
        color: var(--font-color-primary);
        margin-bottom: 0;
        margin-top: 0;
      }

      a:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &::before,
      &::marker {
        position: absolute;
        color: var(--theme-primary);
        left: 0;
      }
    }
  }
}

.product-card-footer {
  margin-top: var(--space-l);
  border-top: 1px solid var(--color-lightGrey);

  .product-details-empty {
    min-height: var(--space-l);
  }
}
