#notifications-container {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 101;
  padding-top: 10px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0 var(--space-l);
  }
}

.message-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: var(--border-radius-s);
  min-height: 48px;
  padding: 14px 16px;
  margin-bottom: 4px;

  p {
    color: var(--font-color-primary);
    margin: 0;

    a {
      color: var(--font-color-primary);
      text-decoration: underline;
      &:hover {
        color: var(--font-color-primary);
      }
    }
  }

  &.success {
    background-color: var(--color-successLight);
    border: 1px solid var(--color-successDark);
  }

  &.negative {
    background: var(--color-errorLight);
    border: 1px solid var(--color-errorDark);
  }

  &.info {
    flex: 1;
    background: var(--color-warnLight);
    border: 1px solid var(--color-warnDark);
  }

  & > svg {
    margin-right: 10px;
    flex: 0 0 24px;
  }

  & > .nds-button {
    margin-left: auto;
    margin-right: 0px;
    flex: 0 0 24px;
  }
}

svg.toast-x {
  color: RGBA(var(--nds-medium-grey));
}
