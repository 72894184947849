@use "sass:map";

.navBar {
  position: sticky;
  left: 0;
  right: 0;
  top: 0;

  &.always-visible-navBar {
    background: var(--bgColor-white);
    box-shadow: var(--elevation-default);

    @media (min-width: $desktop-small) {
      box-shadow: 0px 2px 12px rgba(80, 80, 80, 0.05);
    }
  }

  .nds-container.narmi-grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-s) 0;

    @media (min-width: $tablet) {
      padding: 14px 0;
    }
  }

  .nds-navbar-hamburger {
    flex: 0 0 40px;
    cursor: pointer;
    padding: 4px;
  }

  .profile-option {
    color: var(--font-color-primary);
    display: block;
    position: relative;
    cursor: pointer;
    font-size: var(--font-size-default);
    line-height: var(--font-lineHeight-bigText);
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: var(--button-radius);
    font-weight: var(--font-weight-normal);
    margin-bottom: var(--space-s);

    &:hover {
      text-decoration: underline;
    }

    &:last-of-type {
      margin-bottom: var(--space-l);
    }
  }

  .line {
    border-top: 1px solid var(--color-lightGrey);
    margin: 0 -20px;
  }

  .profile-hover {
    margin: 0 -20px;
    &:hover {
      background: RGBA(var(--theme-rgb-primary), var(--hover-opacity));
      cursor: pointer;
    }
  }

  .linked-profiles-row {
    padding: var(--space-s) 20px var(--space-s) 20px;
    &:hover {
      background: RGBA(var(--theme-rgb-primary), var(--hover-opacity));
      cursor: pointer;
    }

    .linked-profile-icon-section {
      min-width: 24px;
    }
    .linked-profiles-check {
      padding-right: 17px;
      color: RGBA(var(--theme-rgb-primary));
    }

    .linked-profiles-blank-space {
      min-width: 24px;
    }

    .linked-profile-container {
      text-align: left;
    }

    .linked-profiles-name {
      color: RGB(var(--nds-black));
      margin-bottom: 4px;
      font-weight: var(--font-weight-bold);
    }

    .linked-profiles-username {
      color: RGBA(var(--nds-grey));
      font-weight: var(--font-weight-normal);
    }

    .linked-profile-options {
      display: flex;
      flex-direction: row;
      color: var(--color-mediumGrey);
      margin-top: var(--space-xxs);
      * {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .nds-navbar-logo {
    max-height: 44px;
    max-width: 250px;
    cursor: pointer;
  }

  .nds-navbar-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    .nds-navbar-mainmenu {
      flex-grow: 1;
    }
  }
  .nds-details {
    width: 100%;
    text-align: left;

    .wide.details .nds-details-container {
      .account-group {
        margin: 10px 0;
        line-height: var(--font-lineHeight-default);
        .group-header {
          color: var(--color-black);
          font-weight: bold;
          font-size: 16px;
          padding-bottom: 12px;
        }
        .nds-button:last-of-type {
          min-height: 30px;
          .nds-button-content {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .nds-modal > .nds-modal-container {
    max-width: initial;
  }

  button,
  .nds-button,
  .nds-button-content {
    white-space: nowrap;
    text-decoration-color: transparent;
    transition:
      color 0.2s,
      text-decoration-color 0.2s;
  }
  button:hover,
  .nds-button:hover {
    color: var(--theme-primary);
    text-decoration-color: var(--theme-primary);
  }

  .get-started {
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    max-width: 30vw;
    flex-wrap: wrap;
    * {
      line-height: var(--font-lineHeight-bigText);
    }
    @media (min-width: $desktop-small) {
      height: 44px;
      max-width: 35vw;
    }
  }

  // forces the displayed first/last/user name on the user menu to be
  // ~30 char or less
  .nds-navbar-usermenu {
    .nds-modal-container {
      top: -4px;
    }

    .nds-row-item {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (min-width: $desktop-small) {
    z-index: 1;
    background: var(--bgColor-white);
    position: static;
    align-items: center;

    .nds-details {
      width: initial;
    }

    // desktop mode menu flows horizontally
    .nds-navbar-container {
      flex-direction: row;
      overflow-y: initial;
    }
    // desktop mode menu is always visible
    .nds-navbar-hamburger {
      display: none;
    }
    & > .nds-container.narmi-grid > .nds-modal.right {
      flex-grow: 1;
      & > .nds-modal-container {
        display: block;
        position: static;
        padding: 0px 25px;
        & > .nds-modal-dismiss {
          display: none;
        }
      }
    }
    & > .nds-container.narmi-grid > .nds-modal[data-open="true"] > .nds-modal-overlay {
      opacity: 0;
    }
    // underline while hover
    .nds-navbar-container {
      .nds-navbar-mainmenu,
      .nds-navbar-usermenu,
      .nds-details-container,
      .accountMenu,
      .accountMenu-container {
        & > .nds-button--menu:hover,
        & > .AppAuthorize:hover,
        & > .nds-details:hover {
          text-decoration: underline;
        }
      }
    }
    // for the top nav, have a bottom border while hover
    .nds-navbar-container {
      .nds-navbar-mainmenu,
      .nds-navbar-usermenu {
        & > .nds-button--menu,
        & > .AppAuthorize,
        & > .nds-details {
          &:hover {
            text-decoration: none;
          }
          &:after {
            content: "";
            height: 3px;
            background-color: var(--theme-primary);
            position: absolute;
            left: 0;
            right: 0;
            bottom: -18px;
            opacity: 0;
            transition: opacity 0.1s;
          }
          &:hover:after {
            opacity: 1;
          }
        }
        & > .nds-details {
          &:after {
            left: 10px;
            right: 10px;
          }
        }
      }
    }
    // desktop user menu is screen-right-aligned
    .nds-navbar-container .nds-navbar-usermenu {
      $button-padding-minus-details-padding-left: -12px;
      position: absolute;
      right: 30px;
      min-width: 102px;
      .nds-details-container {
        text-align: left;
        min-width: 230px;
        padding-bottom: 0;
      }
      .nds-details-summary button {
        margin-left: $button-padding-minus-details-padding-left;
      }
      & > .nds-details:hover:after {
        left: $button-padding-minus-details-padding-left;
      }
    }
  }
}
