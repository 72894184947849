.options {
  font-weight: var(--font-weight-normal);
  position: relative;
  border-radius: var(--border-radius-s);

  .narmi-icon-more-vertical {
    font-size: var(--font-size-l);
    width: 12px;
    color: var(--color-mediumGrey);
  }

  .open,
  .options-traffic-light:hover {
    border-radius: var(--border-radius-s);
    background-color: RGBA(var(--theme-rgb-primary), var(--ephemeral-opacity));
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:first-child {
    margin-top: var(--space-xxs);
  }

  &:last-child {
    margin-bottom: var(--space-xxs);
    margin-top: var(--space-xxs);
  }

  .option {
    white-space: nowrap;
    width: 100%;
    color: var(--color-grey);
    @media (min-width: $desktop-small) {
      width: 141px;
      height: 32px;
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &:hover {
      background: RGBA(var(--theme-rgb-primary), var(--hover-opacity));
      cursor: pointer;
    }
  }
}
