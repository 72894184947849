%shared-multiline-styles-address {
  line-height: var(--font-lineHeight-default);
  vertical-align: middle;
  font-weight: 400;
  line-height: normal;
  font-family: var(--font-family-body);
  border: none;
  outline: 0;
  padding: 0;
}

.nds-input-address {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--font-size-default);

  .nds-input-box-address {
    border: 1px solid var(--color-lightGrey);
    background: var(--color-white);
    position: relative;
    width: 100%;
    padding: 7px 0.8em;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:focus-within {
      border: 1px solid var(--theme-primary);

      label {
        color: var(--theme-primary);
        margin-bottom: -3px;
      }
    }
  }

  .nds-input-column-address {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
  }

  &.disabled .nds-input-box-address {
    pointer-events: none;
    background-color: var(--color-smokeGrey);

    input {
      color: var(--color-mediumGrey);
    }
  }

  &.error {
    .nds-input-box-address {
      border: 1px solid var(--color-errorDark);
    }

    label {
      color: var(--color-errorDark);
    }
  }

  &.search .nds-input-box-address {
    padding: 3px 0.8em;
  }

  .nds-input-icon-address {
    display: flex;
    font-size: 1.25em;
    padding: 0 0.3em 0 0;
    opacity: 0.5;
  }

  label {
    background: transparent;
    pointer-events: none;
    font-family: var(--font-family-body);
    font-weight: 400;
    font-size: var(--font-size--xs);
    color: var(--color-mediumGrey);
    line-height: 1.25;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: -3px;
  }

  .nds-input-address-error {
    font-size: var(--font-size-xs);
    font-weight: 400;
    color: var(--color-errorDark);
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0.33em 0 0;

    svg {
      margin: -0.1em 0.4em 0 0.2em;
      height: 1.2em;
      width: 1.2em;
    }
  }

  input,
  textarea {
    background: transparent;
  }

  input {
    color: var(--color-grey);
  }

  textarea {
    @extend %shared-multiline-styles-address;
    color: var(--color-grey);
    width: 100%;
    resize: none;
  }

  input[type="text"],
  input[type="search"] {
    border: none;
    outline: 0;
    letter-spacing: 0;
    vertical-align: middle;
    font-family: var(--font-family-body);
    padding: 0;
    width: 100%;
    font-size: var(--font-size-default);
  }

  input[type="text"]:placeholder-shown,
  input[type="search"]:placeholder-shown,
  .no-label input[type="text"],
  .no-label input[type="search"] {
    padding: 6px 0;
    font-size: var(--font-size-default);

    ~ label {
      display: none;
    }
  }

  input,
  textarea {
    &:placeholder,
    &:autofill {
      background: transparent;
      color: var(--color-mediumGrey);
      font-weight: 400;
    }
  }
}

.nds-input-multiline-grid-address {
  display: grid;

  &,
  & > textarea {
    max-height: 80vh; // avoid growing larger than viewport height
    overflow-wrap: anywhere; // always break line if it exceeds available width
  }

  // This pseudo-element is an auto height invisible container that
  // gets filled in with the value of the text area
  &::after {
    content: attr(data-textarea-value) " "; // space prevents jumpy updates
    white-space: pre-wrap; // match textarea default wrapping
    visibility: hidden;

    // match styling of textarea to keep things aligned
    @extend %shared-multiline-styles-address;
  }

  // The growth of the pseudo-element will expand the grid container.
  // The textarea will grow with it, as it is placed over the entire grid area
  &::after,
  & > textarea {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
}

.nds-combobox-address {
  position: relative;
}

.nds-combobox-list-address {
  visibility: hidden;
  position: absolute;
  z-index: 3;
  border: 1px solid var(--theme-primary);
  top: 52px;
  width: 100%;
  max-height: 60vh;
  overflow-y: scroll;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
}

.nds-combobox-list-address--active {
  visibility: visible;
}

.nds-combobox-item-address {
  min-height: var(--space-xl);
}

.nds-combobox-item-address {
  position: relative;
  &:not(:first-child):not(:last-child) {
    cursor: pointer;
  }
  &:hover:not(:first-child):not(:last-child),
  &--highlighted:not(:first-child):not(:last-child) {
    background: RGBA(var(--theme-rgb-primary), var(--alpha-5));
  }
  &--hasGutter {
    padding-left: var(--space-xl) !important;
  }
  .narmi-icon-check {
    position: absolute;
    left: var(--space-s);
    top: 50%;
    transform: translateY(-50%);
  }

  &.title-row {
    text-transform: uppercase;
    color: var(--color-mediumGrey);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 32px;

    :last-child {
      cursor: pointer;
    }
  }
}

//Business Info
.business-info-form {
  display: grid;
  flex-direction: row;
  gap: var(--space-l) var(--space-m);

  @media (min-width: $tablet) {
    grid-template-columns: 3fr 176px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
