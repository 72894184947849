.info-section {
  .header-row {
    display: flex;
    justify-content: space-between;
  }
  .info-row {
    display: flex;
    * {
      flex: 1;
    }
    &:first-of-type {
      margin-top: 0 !important;
    }
  }
}

.shaded-container {
  padding: var(--space-l);
  border-radius: 20px;
  background-color: RGBA(var(--theme-rgb-primary), var(--subdued-5-opacity));
  .review-page &,
  .submitted-container &,
  .confirmed-page & {
    border-radius: 8px;
  }
}

.info-section-white-bg {
  .header-row {
    display: flex;
    justify-content: space-between;
  }
  .info-row {
    display: flex;
    > * {
      flex: 2;
    }
    .info-row-value {
      flex: 3;
    }
    &:first-of-type {
      margin-top: 0 !important;
    }
  }
  .info-row-expanded-key {
    display: flex;
    * {
      flex: 4;
    }
    .info-row-value {
      flex: 1;
    }
    &:first-of-type {
      margin-top: 0 !important;
    }
  }
}

.shaded-container-white-bg {
  padding: var(--space-l);
  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: 0px 2px 12px rgba(80, 80, 80, 0.05);
}
