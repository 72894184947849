.back-cancel-button {
  .back-button-text {
    vertical-align: middle;
  }

  .negative-margin {
    margin-left: -4px;
  }

  .back-button-text.hoverable:hover {
    text-decoration: underline;
  }
}
