.sidebar {
  min-height: 100vh;
  min-width: fit-content;
  width: 336px;
  padding: 62px;
  display: none;

  @media (min-width: $desktop-small) {
    display: block;
  }

  .step {
    margin-top: var(--space-l);
    white-space: nowrap;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.top-bar {
  min-height: 62px;
  min-width: fit-content;
  background-color: RGBA(var(--theme-rgb-primary), var(--subdued-10-opacity));
  padding: 20px 31px 20px 31px;

  @media (min-width: $desktop-small) {
    display: none;
  }
}

.sidebar,
.top-bar {
  .logo {
    /* hide text */
    text-indent: -100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0px;

    /* size constraints */
    display: block;
    width: 200px;
    height: 35px;

    @media (min-width: $desktop-small) {
      height: 50px;
    }

    /* background scaling */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
  }
}
