.mfa-button-wrapper {
  padding-top: var(--space-xxs);
  .nds-button--primary {
    margin-left: var(--space-m);
  }
}

.add-phone-mfa-device-row > .nds-row {
  @media (max-width: $mobile_size) {
    display: block;
    .mfa-button-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: var(--space-l);
    }
  }
}
